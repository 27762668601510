exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-es-js": () => import("./../../../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brows-en-js": () => import("./../../../src/pages/brows.en.js" /* webpackChunkName: "component---src-pages-brows-en-js" */),
  "component---src-pages-brows-es-js": () => import("./../../../src/pages/brows.es.js" /* webpackChunkName: "component---src-pages-brows-es-js" */),
  "component---src-pages-brows-js": () => import("./../../../src/pages/brows.js" /* webpackChunkName: "component---src-pages-brows-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-es-js": () => import("./../../../src/pages/contact.es.js" /* webpackChunkName: "component---src-pages-contact-es-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-privacy-en-js": () => import("./../../../src/pages/data-privacy.en.js" /* webpackChunkName: "component---src-pages-data-privacy-en-js" */),
  "component---src-pages-data-privacy-es-js": () => import("./../../../src/pages/data-privacy.es.js" /* webpackChunkName: "component---src-pages-data-privacy-es-js" */),
  "component---src-pages-data-privacy-js": () => import("./../../../src/pages/data-privacy.js" /* webpackChunkName: "component---src-pages-data-privacy-js" */),
  "component---src-pages-experts-en-js": () => import("./../../../src/pages/experts.en.js" /* webpackChunkName: "component---src-pages-experts-en-js" */),
  "component---src-pages-experts-es-js": () => import("./../../../src/pages/experts.es.js" /* webpackChunkName: "component---src-pages-experts-es-js" */),
  "component---src-pages-experts-js": () => import("./../../../src/pages/experts.js" /* webpackChunkName: "component---src-pages-experts-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-es-js": () => import("./../../../src/pages/faq.es.js" /* webpackChunkName: "component---src-pages-faq-es-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hair-en-js": () => import("./../../../src/pages/hair.en.js" /* webpackChunkName: "component---src-pages-hair-en-js" */),
  "component---src-pages-hair-es-js": () => import("./../../../src/pages/hair.es.js" /* webpackChunkName: "component---src-pages-hair-es-js" */),
  "component---src-pages-hair-js": () => import("./../../../src/pages/hair.js" /* webpackChunkName: "component---src-pages-hair-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lashes-en-js": () => import("./../../../src/pages/lashes.en.js" /* webpackChunkName: "component---src-pages-lashes-en-js" */),
  "component---src-pages-lashes-es-js": () => import("./../../../src/pages/lashes.es.js" /* webpackChunkName: "component---src-pages-lashes-es-js" */),
  "component---src-pages-lashes-js": () => import("./../../../src/pages/lashes.js" /* webpackChunkName: "component---src-pages-lashes-js" */),
  "component---src-pages-makeup-en-js": () => import("./../../../src/pages/makeup.en.js" /* webpackChunkName: "component---src-pages-makeup-en-js" */),
  "component---src-pages-makeup-es-js": () => import("./../../../src/pages/makeup.es.js" /* webpackChunkName: "component---src-pages-makeup-es-js" */),
  "component---src-pages-makeup-js": () => import("./../../../src/pages/makeup.js" /* webpackChunkName: "component---src-pages-makeup-js" */),
  "component---src-pages-nails-en-js": () => import("./../../../src/pages/nails.en.js" /* webpackChunkName: "component---src-pages-nails-en-js" */),
  "component---src-pages-nails-es-js": () => import("./../../../src/pages/nails.es.js" /* webpackChunkName: "component---src-pages-nails-es-js" */),
  "component---src-pages-nails-js": () => import("./../../../src/pages/nails.js" /* webpackChunkName: "component---src-pages-nails-js" */),
  "component---src-pages-specials-en-js": () => import("./../../../src/pages/specials.en.js" /* webpackChunkName: "component---src-pages-specials-en-js" */),
  "component---src-pages-specials-es-js": () => import("./../../../src/pages/specials.es.js" /* webpackChunkName: "component---src-pages-specials-es-js" */),
  "component---src-pages-specials-js": () => import("./../../../src/pages/specials.js" /* webpackChunkName: "component---src-pages-specials-js" */)
}

